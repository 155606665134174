* {
  padding: 0;
  margin: 0;
}
body {
 
}
.app-wrapper {
}
.hide {
  display: none !important;
}