.excharge-modal-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.57);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .excharge-modal-content {
    position: relative;
    background: url('https://cdn.tuanzhzh.com/buyudaren/kajuan-kuang-bg.png') no-repeat;
    // background: url('https://cdn.tuanzhzh.com/buyudaren/duihuanzuanshi-bg.png') no-repeat;
    width: 676px;
    height: 708px;
    background-size: contain;
    background-position: center;
  }
  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }
  .title-name {
    font-size: 38px;
    font-family: SSRuanTangTi;
    color: #FCFFFF;
    line-height: 38px;
    letter-spacing: 3px;
  }
  .excharge-close-icon {
    position: absolute;
    display: inline-block;
    width: 66px;
    height: 66px;
    background: url('https://cdn.tuanzhzh.com/buyudaren/guanbi.png') no-repeat;
    background-size: contain;
    background-position: center;
    right: -5px;
    top: -15px;
  }
  .excharge-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;
  }
  .excharge-content-left {
    display: flex;
    // width: 154px;
    height: 144px;
    // background: url('https://cdn.tuanzhzh.com/buyudaren/duihuang-doubi-icon.png') no-repeat;
    // background-size: contain;
    // background-position: center;
    margin-right: 20px;
  }
  .excharge-content-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 38px;
    font-family: SSRuanTangTi;
    color: #FCFFFF;
  }
  .excharge-content-item {
    display: flex;
    align-items: center;
  }
  .item-title {
    position: relative;
    display: inline-block;
    width: 100px;
  }
  .item-input {
    display: inline-block;
    width: 258px;
    height: 48px;
    background: rgba(50,85,111,0.24);
    box-shadow: inset 0px 2px 6px 0px rgba(17,51,72,0.15);
    border-radius: 6px;

    .adm-input-element {
      font-size: 38px;
      font-family: SSRuanTangTi;
      padding-left: 10px;
    }
  }
  .xiaohao-icon::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 33px;
    height: 28px;
    background: url('https://cdn.tuanzhzh.com/buyudaren/zuanshi.png') no-repeat;
    background-size: contain;
    background-position: center;
    right: -40px;
    top: 5px;
  }
  .doubi-item {
    margin-top: 20px;
  }
  .zuanshi-input {
    --color: #FFFFFF;
    .adm-input-element {
      padding-left: 50px;
    }
  }
  .doubi-input {
    --color: #FFE8C2;
  }
  .douyin-number-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 28px 0;
    .douyin-title {
      font-size: 38px;
      font-family: SSRuanTangTi;
      color: #FCFFFF;
      line-height: 38px;
    }
    .douyin-input-style {
      width: 378px;
      height: 60px;
      background: #FFFFFF;
      box-shadow: inset 0px 2px 6px 0px rgba(17,51,72,0.32);
      border-radius: 10px;
      border: 2px solid #4A99D2;

      --placeholder-color: #4C90C1;
      --color:#4C90C1;
      padding-left: 15px;
    }
  }
  .excharge-explain-word {
    margin: 0 auto;
    width: 574px;
    height: 180px;
    background: rgba(177,222,255,0.44);
    border-radius: 12px;
    font-size: 26px;
    font-family: Alibaba-PuHuiTi-R,Alibaba-PuHuiTi;
    font-weight: normal;
    color: #376D93;
    line-height: 38px;

    .excharce-space-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 14px 0 0 30px;

      span:before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #417DA8;
        vertical-align: middle;
        margin-right: 15px;
        position: relative;
        top: -4px;
      }
    }
  }
  .excharge-sure-btn-wrapper {
    width: 209px;
    height: 58px;
    margin: 40px auto 0;
    background: url('https://cdn.tuanzhzh.com/buyudaren/querenduihuan.png') no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-family: SSRuanTangTi;
    color: #FFFFFF;
    line-height: 34px;
    letter-spacing: 2px;
  }
}