.login-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: url('https://cdn.tuanzhzh.com/buyudaren/my-bg.png') no-repeat;
  background-size: cover;
  background-position: center;
  font-family: SSRuanTangTi;
  overflow-y: auto;

  .login-back {
    width: 68px;
    height: 66px;
    background: url('https://cdn.tuanzhzh.com/buyudaren/fanhui.png') no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 42px;
    left: 40px;
  }

  .login-user-content {
    display: flex;
    justify-items: center;
  }
  .login-user-header {
    width: 230px;
    height: 228px;
    background: url('https://cdn.tuanzhzh.com/buyudaren/login-icon.png') no-repeat;
    background-size: cover;
    background-position: center;
    margin: 276px auto 20px;
  }
  .login-usr-welcome-content {
    text-align: center;
    font-size: 34px;
    font-family: Alibaba-PuHuiTi-R,Alibaba-PuHuiTi;
    font-weight: normal;
    color: #EFF8FF;
    line-height: 46px;
  }
  .login-user-content {
    width: 696px;
    height: 664px;
    background: #FCFFFF;
    border-radius: 22px;
    margin: 56px auto 0;
  }
  .content-wrapper {
    width: 600px;
    --border-inner: none !important;
    ---border-top: none !important;
    ---border-bottom: none !important;
    margin: 110px auto 0;

    .input-style {
      width: 540px;
      height: 80px;
      background: #FFFFFF;
      // box-shadow: inset 0px 2px 6px 0px rgba(17,51,72,0.32);
      border-radius: 10px;
      border: 2px solid #4A99D2;
      padding-left: 10px;
      --placeholder-color: rgba(76,144,193, 0.29);
      --color:#4C90C1;
    }
  }
  .adm-form-item-feedback-error {
    position: absolute;
    z-index: 1;
  }
  .register-btn {
    width: 558px;
    height: 95px;
    background: url('https://cdn.tuanzhzh.com/buyudaren/zhuce-btn.png') no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    font-family: SSRuanTangTi;
    color: #FFFFFF;
    line-height: 34px;
    letter-spacing: 20px;
    margin: 0 auto;
    position: relative;
    left: 10px;
    top: 20px;
  }
  .login-bottom-btn {
    width: 558px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px auto 0;
    font-size: 28px;
    font-family: Alibaba-PuHuiTi-R,Alibaba-PuHuiTi;
    font-weight: normal;
    color: #4C90C1;

    .btn-one {
      margin-left: 15px;
    }
  }
}